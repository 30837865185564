import React from 'react';
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import OAuth2Form from '../oauth2/OAuth2Form'
import { signup } from './SignupAPI';
import { useRequireAuth } from '../../auth/useAuth';
import { Form, Input, Button, notification } from 'antd';
import { EMAIL_MAX_LENGTH, NAME_MAX_LENGTH, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, USERNAME_MAX_LENGTH } from '../../constants';
import './Signup.less';
import { LoadingIndicator, Separator } from '../../common/components';

function Signup() {
    const { isLoading } = useRequireAuth({redirectTo: "/", redirectIfFound: true})

    const history = useHistory()

    if(isLoading) {
        return <LoadingIndicator />
    }

    const handleSignup = async (signupRequest) => {
        try {
            await signup(signupRequest)
            notification.success({
                message: 'SimplePoll',
                description: "You're successfully registered. Please login to continue!"
            })
            
            history.push("/login");
        } catch (err) {
            notification.error({
                message: 'SimplePoll',
                description: (err && err.message) || 'Oops! Something went wrong. Please try again!'
            })
        }
    }

    return (
        <div className="signup-container">
            <div className="signup-content">
                <h1 className="signup-title">Signup with SimplePoll</h1>
                <OAuth2Form />
                <Separator />
                <SignupForm onSignup={handleSignup} />
                <div className="login-link">Already have an account? <Link to="/login">Login!</Link></div>
            </div>
        </div>
    );

}

function SignupForm(props) {
    return (
        <Form
            name="signup-form"
            onFinish={props.onSignup}
            size="large"
            layout="vertical"
        >
            <Form.Item
                name="name"
                rules={[
                    { required: true, type: 'string', message: 'Please input your name!' }, 
                    { max: NAME_MAX_LENGTH, message: `Name is too long (Maximum ${NAME_MAX_LENGTH} characters allowed)` }
                ]}
            >
                <Input placeholder="Full Name" />
            </Form.Item>

            <Form.Item
                name="username"
                rules={[
                    { required: true, type: 'string', message: 'Please input your username!' },
                    { max: USERNAME_MAX_LENGTH, message: `Username is too long (Maximum ${USERNAME_MAX_LENGTH} characters allowed)` }
                ]}
            >
                <Input placeholder="Username" />
            </Form.Item>

            <Form.Item
                name="email"
                rules={[
                    { required: true, type: 'email', message: 'Please input a valid email!' },
                    { max: EMAIL_MAX_LENGTH, message: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)` }
                ]}
            >
                <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    { required: true, message: 'Please input your password!' },
                    { min: PASSWORD_MIN_LENGTH, message: `Please enter a valid password (with at least ${PASSWORD_MIN_LENGTH} characters)` },
                    { max: PASSWORD_MAX_LENGTH, message: `Password is too long (Maximum ${PASSWORD_MAX_LENGTH} characters allowed)` }
                ]}
            >
                <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" size="large" block>
                    Signup
                </Button>
            </Form.Item>
        </Form>

    );

}

SignupForm.propTypes = {
    onSignup: PropTypes.func.isRequired
}

export default Signup