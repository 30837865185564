import React from 'react';
import {
    LoadingOutlined,
} from '@ant-design/icons';

export default function LoadingIndicator(props) {
    return (
        <div className="loading-indicator" style={{display: 'block', textAlign: 'center', marginTop: '30px'}}>
            <LoadingOutlined />
        </div>
    );
}