import React from 'react'
import './Separator.less';

function Separator({text = "OR"}) {
    return (
        <div className="line-separator">
            <span className="line-separator-text">{text}</span>
        </div>
    )
}

export default Separator