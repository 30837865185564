import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { LoadingIndicator } from '../../common/components'
import { useAuth } from '../../auth/useAuth';

function OAuth2RedirectHandler(props) {
    const history = useHistory()
    const location = useLocation()

    const { onLogin } = useAuth()

    useEffect(() => {
        const query = new URLSearchParams(location.search)

        const token = query.get('token');
        const error = query.get('error');
    
        if(token) {
            onLogin(token);
        } else {
            history.replace('/login', { 
                from: location,
                error: error 
            })
        }
    
    }, [onLogin, history, location])

    return (
        <LoadingIndicator />
    )
}

export default OAuth2RedirectHandler;