import React from 'react';
import {
    Route,
    Switch
} from 'react-router-dom';
import PollList from '../poll/PollList';
import NewPoll from '../poll/NewPoll';
import Login from '../auth/login/Login';
import Signup from '../auth/signup/Signup';
import Profile from '../profile/Profile'
import OAuth2RedirectHandler from '../auth/oauth2/OAuth2RedirectHandler';
import { AppHeader, NotFound } from '../common/components';
import Layout, { Content } from 'antd/lib/layout/layout';
import { ProvideAuth } from "../auth/useAuth";
import './App.less';

function App() {    
    return (
        <ProvideAuth>
            <Layout className="app layout-background">
                <AppHeader />
                <Content className="app-content">
                    <Switch>
                        <Route exact path="/" component={PollList}></Route>
                        <Route path="/login" component={Login}></Route>
                        <Route path="/signup" component={Signup}></Route>
                        <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>
                        <Route path="/users/:username" component={Profile}></Route>
                        <Route component={NewPoll}></Route>
                        <Route component={NotFound}></Route>
                    </Switch>
                </Content>
            </Layout>
        </ProvideAuth>
    );
}

export default App;