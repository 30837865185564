import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { createPoll } from './PollAPI';
import { MAX_CHOICES, POLL_QUESTION_MAX_LENGTH, POLL_CHOICE_MAX_LENGTH } from '../constants';
import { Form, Input, Button, Select, notification } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import './NewPoll.less';
import { useRequireAuth } from '../auth/useAuth';
import { LoadingIndicator } from '../common/components';

const Option = Select.Option;
const { TextArea } = Input

function NewPoll(props) {
    const { isLoading } = useRequireAuth({redirectTo: "/login"})

    const history = useHistory()
    const formRef = useRef()

    const handleSubmit = (values) => {
        const pollData = {
            question: values.question,
            choices: values.choices,
            poll_length: values.pollLength
        };

        createPoll(pollData)
            .then(response => {
                history.push("/");
            }).catch(error => {
                if (error.status === 401) {
                    props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
                } else {
                    notification.error({
                        message: 'Polling App',
                        description: error.message || 'Sorry! Something went wrong. Please try again!'
                    });
                }
            });
    }

    const onPollLengthChange = (type, value) => {
        const lengthValue = parseInt(value)
        switch (type) {
            case 'days':
                formRef.current.setFieldsValue({ pollLength: { days: lengthValue } })
                return
            case 'hours':
                formRef.current.setFieldsValue({ pollLength: { hours: lengthValue } })
                return
            case 'minutes':
                formRef.current.setFieldsValue({ pollLength: { minutes: lengthValue } })
                return
            default:
                return    
        }
    }

    if(isLoading) {
        return <LoadingIndicator />
    }

    return (
        <div className="new-poll-container">
            <h1 className="page-title">Create Poll</h1>
            <div className="new-poll-content">
                <Form
                    name="create-poll-form"
                    className="create-poll-form"
                    layout="vertical"
                    autoComplete="off"
                    ref={formRef}
                    onFinish={handleSubmit}
                    initialValues={{ question: '', choices: ['', ''], pollLength: { days: 1, hours: 0, minutes: 0 } }}>
                    <Form.Item
                        name="question"
                        className="poll-form-item"
                        rules={[
                            { required: true, message: 'Please enter your question!' },
                            { max: POLL_QUESTION_MAX_LENGTH, message: `Question is too long (Maximum ${POLL_QUESTION_MAX_LENGTH} characters allowed)` }
                        ]}>
                        <TextArea
                            placeholder="Enter your question"
                            style={{ fontSize: '16px' }}
                            autosize={{ minRows: 3, maxRows: 6 }}
                            name="question" />
                    </Form.Item>
                    <Form.List
                        name="choices"
                        rules={[
                            {
                                validator: async (_, names) => {
                                    if (!names || names.length < 2) {
                                        return Promise.reject(new Error('At least 2 choices'));
                                    }
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        className="poll-form-item"
                                        required={false}
                                        key={field.key}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: `Please input some text ${index > 1 ? 'or delete this choice' : ''}`
                                                },
                                                {
                                                    max: POLL_CHOICE_MAX_LENGTH,
                                                    message: `Choice is too long (maximum ${POLL_QUESTION_MAX_LENGTH} characters allowed)`
                                                }
                                            ]}
                                            noStyle
                                        >
                                            <Input placeholder={`Choice ${index + 1}`} size="large" className={index > 1 ? 'optional-choice' : null} />
                                        </Form.Item>
                                        {index > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Form.Item>
                                ))}
                                {fields.length < MAX_CHOICES ?
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{ width: '50%' }}
                                            icon={<PlusOutlined />}
                                        >
                                            Add choice
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                    : null
                                }
                            </>
                        )}
                    </Form.List>
                    <Form.Item
                        label="Poll Length"
                        className="poll-form-item">
                        <div>
                            <Form.Item name={['pollLength', 'days']} className="poll-length-selector">
                                <span>
                                    <Select
                                        defaultValue={1}
                                        onChange={value => onPollLengthChange('days', value)}
                                        style={{ width: 60 }} >
                                        {
                                            Array.from(Array(8).keys()).map(i =>
                                                <Option key={i}>{i}</Option>
                                            )
                                        }
                                    </Select> &nbsp;Days
                                </span>
                            </Form.Item>
                            <Form.Item name={['pollLength', 'hours']} className="poll-length-selector">
                                <span>
                                    <Select
                                        defaultValue={0}
                                        onChange={value => onPollLengthChange('hours', value)}
                                        style={{ width: 60 }} >
                                        {
                                            Array.from(Array(24).keys()).map(i =>
                                                <Option key={i}>{i}</Option>
                                            )
                                        }
                                    </Select> &nbsp;Hours
                                </span>
                            </Form.Item>
                            <Form.Item name={['pollLength', 'minutes']} className="poll-length-selector">
                                <span>
                                    <Select
                                        defaultValue={0}
                                        onChange={value => onPollLengthChange('minutes', value)}
                                        style={{ width: 60 }} >
                                        {
                                            Array.from(Array(60).keys()).map(i =>
                                                <Option key={i}>{i}</Option>
                                            )
                                        }
                                    </Select> &nbsp;Minutes
                                </span>
                            </Form.Item>
                        </div>
                    </Form.Item>
                    <Form.Item className="poll-form-row">
                        <Button type="primary"
                            htmlType="submit"
                            size="large"
                            className="create-poll-form-button">Create Poll</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );

}

export default NewPoll;