import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd'
import './NotFound.less';

function NotFound(props) {
    return (
        <div className="page-not-found">
            <h1 className="title">
                404
            </h1>
            <div className="desc">
                The Page you're looking for was not found.
            </div>
            <Link to="/"><Button type="primary">Go Back</Button></Link>
        </div>
    )

}

export default NotFound;