import { API_BASE_URL, ACCESS_TOKEN } from '../../constants';

class APIError extends Error {
    constructor(status, body) {
        super((body && body.message) || 'Something went wrong!');
        this.status = status;
        this.body = body;
    }
}

export const fetcher = async (url, method = 'GET', body) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    let request = {
        method: method,
        headers: headers,
    };

    if (body) {
        request.body = JSON.stringify(body)
    }

    const response = await fetch(API_BASE_URL + url, request)
    if (!response.ok) {
        const body = await response.json()
        throw new APIError (response.status, body)
    } else {
        return await response.json()
    }
};

export async function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        throw new Error("You're not logged in")
    }

    return fetcher('/user/me');
}