import { fetcher } from '../common/utils/APIUtils';
import { POLL_LIST_SIZE } from '../constants';

export async function getAllPolls(cursor = null) {
    return fetcher(`/polls?count=${POLL_LIST_SIZE}${cursor != null ? `&cursor=${cursor}`: ""}`);
}

export async function createPoll(pollRequest) {
    return fetcher('/polls', 'POST', pollRequest);
}

export async function castVote(voteRequest) {
    return fetcher(`/polls/${voteRequest.poll_id}/votes`, 'POST', voteRequest);
}

export async function getUserCreatedPolls(username, cursor = null) {
    return fetcher(`/users/${username}/polls?count=${POLL_LIST_SIZE}${cursor != null ? `&cursor=${cursor}`: ""}`);
}

export async function getUserVotedPolls(username, cursor = null) {
    return fetcher(`/users/${username}/votes?count=${POLL_LIST_SIZE}${cursor != null ? `&cursor=${cursor}`: ""}`);
}