import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAllPolls, getUserCreatedPolls, getUserVotedPolls, castVote } from './PollAPI';
import Poll from './Poll';
import { LoadingIndicator } from '../common/components';
import { Button, Icon, notification } from 'antd';
import './PollList.less';
import { useAuth } from '../auth/useAuth';
import { useHistory } from 'react-router-dom';

function PollList(props) {
    const { isAuthenticated, isLoading } = useAuth()

    if(isLoading) {
        return <LoadingIndicator />
    }

    return <PollListView isAuthenticated={isAuthenticated} username={props.username} type={props.type} />
}

function PollListView(props) {
    const history = useHistory()
    const [data, setData] = useState({ polls: [], nextCursor: null })
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {        
        getPolls(props.username, props.type)
            .then(response => {
                setData({
                    polls: response.data,
                    nextCursor: response.next_cursor
                })
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
            })
    }, [props.isAuthenticated, props.username, props.type])

    const getPolls = (username, type, nextCursor) => {
        let promise;
        if (username) {
            if (type === 'USER_CREATED_POLLS') {
                promise = getUserCreatedPolls(username, nextCursor);
            } else if (type === 'USER_VOTED_POLLS') {
                promise = getUserVotedPolls(username, nextCursor);
            }
        } else {
            promise = getAllPolls(nextCursor);
        }

        return promise;
    }

    const handleLoadMore = () => {
        setIsLoading(true)

        getPolls(props.username, props.type, data.nextCursor)
            .then(response => {
                setData({
                    polls: data.polls.concat(response.data),
                    nextCursor: response.next_cursor
                })
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
            })
    }

    const handleVoteSubmit = async (pollIndex, selectedChoiceId) => {
        if (!props.isAuthenticated) {
            history.push("/login");
            notification.info({
                message: 'Polling App',
                description: "Please login to vote.",
            });
            return;
        }

        const poll = data.polls[pollIndex];

        const voteData = {
            poll_id: poll.id,
            choice_id: selectedChoiceId
        };

        try {
            await castVote(voteData)

            const polls = data.polls.slice();
            polls[pollIndex].selected_choice = selectedChoiceId;
            polls[pollIndex].total_votes += 1;

            const selectedChoiceIndex = polls[pollIndex].choices.findIndex(choice => choice.id === selectedChoiceId)
            polls[pollIndex].choices[selectedChoiceIndex].vote_count += 1

            setData(prevData => ({
                polls: polls,
                nextCursor: prevData.nextCursor
            }))
        } catch (err) {
            notification.error({
                message: 'Polling App',
                description: (err && err.message) || 'Sorry! Something went wrong. Please try again!'
            });
        }
    }

    const pollViews = [];
    data.polls.forEach((poll, pollIndex) => {
        pollViews.push(<Poll
            key={poll.id}
            poll={poll}
            handleVoteSubmit={(selectedChoiceId) => handleVoteSubmit(pollIndex, selectedChoiceId)} />)
    });

    if (isLoading) {
        return <LoadingIndicator />
    }

    return (
        <div className="polls-container">
            {pollViews}
            {
                data.polls.length === 0 ? (
                    <div className="no-polls-found">
                        <span>No Polls Found.</span>
                    </div>
                ) : null
            }
            {
                data.nextCursor ? (
                    <div className="load-more-polls">
                        <Button type="dashed" onClick={handleLoadMore} disabled={isLoading}>
                            <Icon type="plus" /> Load more
                        </Button>
                    </div>) : null
            }
        </div>
    );    
}

PollList.propTypes = {
    username: PropTypes.string,
    type: PropTypes.oneOf(['USER_CREATED_POLLS', 'USER_VOTED_POLLS'])
}

PollListView.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    username: PropTypes.string,
    type: PropTypes.oneOf(['USER_CREATED_POLLS', 'USER_VOTED_POLLS'])
}

export default PollList;