import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { login } from './LoginAPI';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useRequireAuth } from '../../auth/useAuth';
import OAuth2Form from '../oauth2/OAuth2Form'
import { Form, Input, Button, notification } from 'antd';
import './Login.less';
import { LoadingIndicator, Separator } from '../../common/components';

function Login() {
    const { isLoading, onLogin } = useRequireAuth({redirectTo: "/", redirectIfFound: true})
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        if (location.state && location.state.error) {
            setTimeout(() => {
                notification.error({
                    message: 'SimplePoll',
                    description: location.state.error,
                })

                history.replace({
                    pathname: location.pathname,
                    state: {}
                });
            }, 100);
        }
    }, [location, history])

    const handleLogin = async (loginRequest) => {
        try {
            const response = await login(loginRequest)
            onLogin(response.access_token)
        } catch (err) {
            notification.error({
                message: 'SimplePoll',
                description: (err && err.message) || 'Oops! Something went wrong. Please try again!'
            });
        }
    }

    if(isLoading) {
        return <LoadingIndicator />
    }

    return (
        <div className="login-container">
            <div className="login-content">
                <h1 className="login-title">Login to SimplePoll</h1>
                <OAuth2Form />
                <Separator />
                <LoginForm onLogin={handleLogin} />
                <div className="signup-link">New user? <Link to="/signup">Sign up!</Link></div>
            </div>
        </div>
    );

}

function LoginForm(props) {
    return (
        <Form
            name="login-form"
            onFinish={props.onLogin}
            size="large"
            layout="vertical"
        >
            <Form.Item
                name="username_or_email"
                rules={[{ required: true, type: 'string', message: 'Please input your username or email!' }]}
            >
                <Input placeholder="Username or Email" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" size="large" block>
                    Login
                </Button>
            </Form.Item>
        </Form>
    );
}

LoginForm.propTypes = {
    onLogin: PropTypes.func.isRequired
}

export default Login
