import React from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { getAvatarColor } from '../common/utils/ColorsUtils';
import { formatDateTime } from '../common/utils/DateTimeUtils';
import {
    CheckCircleOutlined
} from '@ant-design/icons'
import { Radio } from 'antd';
import './Poll.less';

const RadioGroup = Radio.Group;

function Poll(props) {
    
    const calculatePercentage = (choice) => {
        if (props.poll.total_votes === 0) {
            return 0;
        }
        return (choice.vote_count * 100) / (props.poll.total_votes);
    };

    const isSelected = (choice) => {
        return props.poll.selected_choice === choice.id;
    }

    const getWinningChoices = () => {
        if (props.poll.total_votes === 0) {
            return []
        }

        const maxVoteCount = Math.max(...props.poll.choices.map(c => c.vote_count))
        const winners = props.poll.choices.filter(c => c.vote_count === maxVoteCount)

        return winners
    }

    const getTimeRemaining = (poll) => {
        const expirationTime = new Date(poll.expiration_time).getTime();
        const currentTime = new Date().getTime();

        var difference_ms = expirationTime - currentTime;
        var seconds = Math.floor((difference_ms / 1000) % 60);
        var minutes = Math.floor((difference_ms / 1000 / 60) % 60);
        var hours = Math.floor((difference_ms / (1000 * 60 * 60)) % 24);
        var days = Math.floor(difference_ms / (1000 * 60 * 60 * 24));

        let timeRemaining;

        if (days > 0) {
            timeRemaining = days + " days left";
        } else if (hours > 0) {
            timeRemaining = hours + " hours left";
        } else if (minutes > 0) {
            timeRemaining = minutes + " minutes left";
        } else if (seconds > 0) {
            timeRemaining = seconds + " seconds left";
        } else {
            timeRemaining = "less than a second left";
        }

        return timeRemaining;
    }

    const pollChoices = [];
    if (props.poll.selected_choice || props.poll.expired) {
        const winningChoices = props.poll.expired ? getWinningChoices() : [];

        props.poll.choices.forEach(choice => {
            pollChoices.push(<CompletedOrVotedPollChoice
                key={choice.id}
                choice={choice}
                isWinner={winningChoices.some(wc => wc.id === choice.id)}
                isSelected={isSelected(choice)}
                percentVote={calculatePercentage(choice)}
            />);
        });
    } else {
        props.poll.choices.forEach(choice => {
            pollChoices.push(<Radio.Button className="poll-choice-radio" key={choice.id} value={choice.id}>{choice.text}</Radio.Button>)
        })
    }
    return (
        <div className="poll-content">
            <div className="poll-header">
                <div className="poll-creator-info">
                    <Link className="creator-link" to={`/users/${props.poll.created_by.username}`}>
                        <Avatar className="poll-creator-avatar"
                            style={{ backgroundColor: getAvatarColor(props.poll.created_by.name) }} >
                            {props.poll.created_by.name[0].toUpperCase()}
                        </Avatar>
                        <span className="poll-creator-name">
                            {props.poll.created_by.name}
                        </span>
                        <span className="poll-creator-username">
                            @{props.poll.created_by.username}
                        </span>
                        <span className="poll-creation-date">
                            {formatDateTime(props.poll.creation_time)}
                        </span>
                    </Link>
                </div>
                <div className="poll-question">
                    {props.poll.question}
                </div>
            </div>
            <div className="poll-choices">
                <RadioGroup
                    className="poll-choice-radio-group"
                    size="large"
                    onChange={event => props.handleVoteSubmit(event.target.value)}>
                    {pollChoices}
                </RadioGroup>
            </div>
            <div className="poll-footer">                
                <span className="total-votes">{props.poll.total_votes} votes</span>
                <span className="separator">•</span>
                <span className="time-left">
                    {
                        props.poll.expired ? "Final results" :
                            getTimeRemaining(props.poll)
                    }
                </span>
            </div>
        </div>
    );
}

function CompletedOrVotedPollChoice(props) {
    return (
        <div className="cv-poll-choice">
            <span className="cv-poll-choice-details">
                <span className="cv-choice-percentage">
                    {Math.round(props.percentVote * 100) / 100}%
                </span>
                <span className="cv-choice-text">
                    {props.choice.text}
                </span>
                {
                    props.isSelected ? 
                        <CheckCircleOutlined className="selected-choice-icon" />
                         : null                    
                }
            </span>
            <span className={props.isWinner ? 'cv-choice-percent-chart winner' : 'cv-choice-percent-chart'}
                style={{ width: `${props.percentVote ? `${props.percentVote}%` : `7px`}` }}>
            </span>
        </div>
    );
}


export default Poll;