import React, { useEffect, useContext, useReducer, createContext } from 'react'
import { ACCESS_TOKEN } from '../constants'
import { getCurrentUser } from '../common/utils/APIUtils';

import {
    useHistory
} from 'react-router-dom'

const initialState = {
    isAuthenticated: false,
    isLoading: true,
    user: null,
    error: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'INITIALIZED':
        case 'LOGIN':
            return {
                ...state,
                isLoading: false,
                isAuthenticated: !!action.user,
                user: action.user,
                error: null,
            };
        case 'LOGOUT':
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                user: null,
                error: null,
            };
        case 'ERROR':
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                user: null,
                error: action.error,
            }
        default:
            throw new Error();
    }
}

const authContext = createContext()

export function ProvideAuth({ children }) {
    const auth = useProvideAuth()
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
    return useContext(authContext)
}

function useProvideAuth() {
    const history = useHistory()
    const [state, dispatch] = useReducer(reducer, initialState)

    const onLogout = () => {
        localStorage.removeItem(ACCESS_TOKEN)
        dispatch({ type: 'LOGOUT' })
    }

    const onLogin = async (accessToken) => {
        localStorage.setItem(ACCESS_TOKEN, accessToken)
        try {
            const user = await getCurrentUser()
            dispatch({ type: 'LOGIN', user: user })
            history.replace("/")
        } catch (err) {
            dispatch({ type: 'ERROR', error: err })
        }
    }

    useEffect(() => {
        const loadAuthenticatedUser = async () => {
            try {
                const user = await getCurrentUser()
                dispatch({ type: 'INITIALIZED', user: user })
            } catch (err) {
                dispatch({ type: 'ERROR', error: err })
            }
        }

        loadAuthenticatedUser()
    }, [])

    return {
        ...state,
        onLogin,
        onLogout,
    }
}

export function useRequireAuth({ redirectTo, redirectIfFound } = {}) {
    const auth = useAuth()
    const history = useHistory()

    useEffect(() => {
        if (
            // If redirectTo is set, redirect if the user was not found.
            (redirectTo && !redirectIfFound && !auth.user) ||
            // If redirectIfFound is also set, redirect if the user was found
            (redirectIfFound && auth.user)
        ) {
            history.push(redirectTo)
        }
    }, [auth, history, redirectTo, redirectIfFound])

    return auth;
}