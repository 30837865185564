import React from 'react';
import { Button } from 'antd';
import fbLogo from './img/fb-logo.png';
import googleLogo from './img/google-logo.png';
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL } from '../../constants';
import './OAuth2Form.less';

export default function OAuth2Form() {
    return (
        <div className="oauth2-form">
            <a className="social-btn" href={GOOGLE_AUTH_URL}>
                <Button size="large" block>
                    <img src={googleLogo} alt="Google" /> Continue with Google
                </Button>
            </a>    
            <a className="social-btn" href={FACEBOOK_AUTH_URL}>
                <Button size="large" block>
                    <img src={fbLogo} alt="Facebook" /> Continue with Facebook
                </Button>
            </a>
        </div>
    );    
}
