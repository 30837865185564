import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PollList from '../poll/PollList';
import { getUserPollSummary, getUserProfile } from './ProfileAPI';
import { Avatar, Tabs } from 'antd';
import { getAvatarColor } from '../common/utils/ColorsUtils';
import { formatDate } from '../common/utils/DateTimeUtils';
import { NotFound, ServerError, LoadingIndicator } from '../common/components';
import './Profile.less';

const TabPane = Tabs.TabPane;

function Profile(props) {
    const { username } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [isNotFound, setIsNotFound] = useState(false)
    const [isServerError, setIsServerError] = useState(false)

    const [userPollSummary, setUserPollSummary] = useState(null)
    const [userProfile, setUserProfile] = useState(null)

    useEffect(() => {
        const fetchUserProfileAndSummary = async () => {
            try {
                const userProfileResponse = await getUserProfile(username)
                setUserProfile(userProfileResponse)
    
                const userPollSummaryResponse = await getUserPollSummary(username)
                setUserPollSummary(userPollSummaryResponse)
            } catch (err) {
                if(err.status === 404) {
                    setIsNotFound(true)
                } else {
                    setIsServerError(true)
                }
            }
            setIsLoading(false)
        }
        
        fetchUserProfileAndSummary()
    }, [username, props.isAuthenticated])


    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (isNotFound) {
        return <NotFound />
    }

    if (isServerError) {
        return <ServerError />
    }

    return (
        <div className="profile">
            {
                userProfile ? (
                    <div className="user-profile">
                        <div className="user-details">
                            <div className="user-avatar">
                                <Avatar className="user-avatar-circle" style={{ backgroundColor: getAvatarColor(userProfile.name) }}>
                                    {userProfile.name[0].toUpperCase()}
                                </Avatar>
                            </div>
                            <div className="user-summary">
                                <div className="full-name">{userProfile.name}</div>
                                <div className="username">@{userProfile.username}</div>
                                <div className="user-joined">
                                    Joined {formatDate(userProfile.created_at)}
                                </div>
                            </div>
                        </div>
                        <div className="user-poll-details">
                            <Tabs defaultActiveKey="1"
                                animated={false}
                                size="large"
                                className="profile-tabs"
                                centered>
                                <TabPane tab={`${userPollSummary.poll_count} Polls`} key="1">
                                    <PollList username={username} type="USER_CREATED_POLLS" />
                                </TabPane>
                                <TabPane tab={`${userPollSummary.vote_count} Votes`} key="2">
                                    <PollList username={username} type="USER_VOTED_POLLS" />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                ) : null
            }
        </div>
    );

}

export default Profile;