import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Layout, Menu, Dropdown, Avatar } from 'antd'
import { getAvatarColor } from '../../utils/ColorsUtils';
import './AppHeader.less'
import { useAuth } from '../../../auth/useAuth';
const { Header } = Layout

function AppHeader() {
    const { isAuthenticated, isLoading, user, onLogout } = useAuth()

    const handleMenuClick = ({ key }) => {
        if (key === "logout") {
            onLogout();
        }
    }

    let menuItems
    if (isLoading) {
        menuItems = []
    } else {
        if (isAuthenticated) {
            menuItems = [
                <Menu.Item key="poll">
                    <Link to="/poll/new">
                        Create Poll
                    </Link>
                </Menu.Item>,
                <Menu.Item key="profile" className="profile-menu">
                    <ProfileDropdownMenu
                        currentUser={user}
                        handleMenuClick={handleMenuClick} />
                </Menu.Item>
            ]
        } else {
            menuItems = [
                <Menu.Item key="login">
                    <NavLink to="/login">Login</NavLink>
                </Menu.Item>,
                <Menu.Item key="signup">
                    <NavLink to="/signup">Signup</NavLink>
                </Menu.Item>,
            ]
        }
    }

    return (
        <Header className="app-header">
            <div className="app-branding">
                <Link to="/" className="app-title">SimplePoll</Link>
            </div>
            <div className="app-options">
                <Menu theme="light" mode="horizontal" style={{ justifyContent: 'end' }}>
                    {menuItems}
                </Menu>
            </div>
        </Header>
    )
}

function ProfileDropdownMenu(props) {
    const dropdownMenu = (
        <Menu onClick={props.handleMenuClick} >
            <Menu.Item key="profile" className="dropdown-item">
                <Link to={`/users/${props.currentUser.username}`}>
                    <div className="user-full-name-info">
                        {props.currentUser.name}
                    </div>
                    <div className="username-info">
                        @{props.currentUser.username}
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" className="dropdown-item">
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            overlay={dropdownMenu}
            trigger={['click']}
            placement="bottomRight">
            <div className="username-info">
                <Avatar
                    style={{ backgroundColor: getAvatarColor(props.currentUser.name) }} >
                    {props.currentUser.name[0].toUpperCase()}
                </Avatar>
            </div>

        </Dropdown>
    );
}

export default AppHeader;