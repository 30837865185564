export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const OAUTH2_REDIRECT_URL = process.env.REACT_APP_OAUTH2_REDIRECT_URL;

export const GOOGLE_AUTH_URL = `${API_BASE_URL}/oauth2/authorize/google?redirect_url_after_login=${OAUTH2_REDIRECT_URL}`;
export const FACEBOOK_AUTH_URL = `${API_BASE_URL}/oauth2/authorize/facebook?redirect_url_after_login=${OAUTH2_REDIRECT_URL}`;

export const ACCESS_TOKEN = 'accessToken';

export const POLL_LIST_SIZE = 30;
export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 30;

export const NAME_MAX_LENGTH = 40;
export const USERNAME_MAX_LENGTH = 15;
export const EMAIL_MAX_LENGTH = 60;
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 30;